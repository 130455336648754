<template>
  <div class="home">
    <button @click="vibrationBtn">Vibration</button>
  </div>
  <div>
    <h3>gyroscope</h3>
    <p>x: {{ gyroscope.x }}</p>
    <p>y: {{ gyroscope.y }}</p>
    <p>z: {{ gyroscope.z }}</p>
  </div>
  <div>
    <h3>position</h3>
    <button @click="geo">position</button>
    <p>latitude: {{ position.latitude }}</p>
    <p>longitude: {{ position.longitude }}</p>
  </div>
  <div>
    <h3>webcam</h3>
    <button @click="webcam">webcam</button>
    <br />
    <video autoplay="true" id="videoElement"></video>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      position: {},
      gyroscope: {},
    };
  },
  mounted() {
    let gyroscope = new Gyroscope({ frequency: 60 });

    gyroscope.addEventListener("reading", (e) => {
      this.gyroscope.x = gyroscope.x;
      this.gyroscope.y = gyroscope.y;
      this.gyroscope.z = gyroscope.z;
    });
    gyroscope.start();
    window.navigator.vibrate([
      100,
      30,
      100,
      30,
      100,
      30,
      200,
      30,
      200,
      30,
      200,
      30,
      100,
      30,
      100,
      30,
      100,
    ]);
  },
  methods: {
    geo() {
      navigator.geolocation.getCurrentPosition(
        (result) => {
          console.log("success", result);
          this.position.latitude = result.coords.latitude;
          this.position.longitude = result.coords.longitude;
        },
        (error) => {
          console.log("failed", error);
        }
      );
    },
    webcam() {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((mediaStream) => {
          document.querySelector("video").srcObject = mediaStream;

          const track = mediaStream.getVideoTracks()[0];
          imageCapture = new ImageCapture(track);

          return imageCapture.getPhotoCapabilities();
        });
    },
    vibrationBtn() {
      console.log(window.navigator);
      setInterval(() => {
        window.navigator.vibrate([
          100,
          30,
          100,
          30,
          100,
          30,
          200,
          30,
          200,
          30,
          200,
          30,
          100,
          30,
          100,
          30,
          100,
        ]);
      }, 1000);
    },
  },
  mounted() {},
};
</script>
<style>
#videoElement {
  width: 500px;
  height: 375px;
  background-color: #666;
}
</style>
